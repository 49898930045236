@if (!dismissed) {
  <div class="alert-container" [@fadeIn]="!dismissed" [@fadeOut]="!dismissed">
    <!-- Border -->
    @if (appearance === 'border') {
      <div class="alert-border"></div>
    }

    <!-- Icon -->
    @if (showIcon) {
      <div class="alert-icon">
        <!-- Custom icon -->
        <div class="alert-custom-icon">
          <ng-content select="[aceAlertIcon]"></ng-content>
        </div>

        <!-- Default icons -->
        <div class="alert-default-icon">
          @switch (type) {
            @case ('primary') {
              <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
            }
            @case ('accent') {
              <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
            }
            @case ('warn') {
              <mat-icon [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
            }
            @case ('basic') {
              <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
            }
            @case ('info') {
              <mat-icon [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
            }
            @case ('success') {
              <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
            }
            @case ('warning') {
              <mat-icon [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>
            }
            @case ('error') {
              <mat-icon [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
            }
          }
        </div>
      </div>
    }

    <!-- Content -->
    <div class="alert-content">
      <div class="alert-title">
        <ng-content select="[aceAlertTitle]"></ng-content>
      </div>

      <div class="alert-message">
        <ng-content></ng-content>
      </div>
    </div>

    <!-- Dismiss button -->
    <button class="alert-dismiss-button" mat-icon-button (click)="dismiss()">
      <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
  </div>
}
